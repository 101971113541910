;(function (global) {
	global.dateDropperSetup.languages['gr'] = {
		name: '\u0395\u03bb\u03bb\u03b7\u03bd\u03b9\u03ba\u03ac',
		m: {
			s: [
				'\u0399\u03b1\u03bd',
				'\u03a6\u03b5\u03b2',
				'\u039c\u03ac\u03c1',
				'\u0391\u03c0\u03c1',
				'\u039c\u03ac\u03b9',
				'\u0399\u03bf\u03cd\u03bd',
				'\u0399\u03bf\u03cd\u03bb',
				'\u0391\u03cd\u03b3',
				'\u03a3\u03b5\u03c0',
				'\u039f\u03ba\u03c4',
				'\u039d\u03bf\u03ad',
				'\u0394\u03b5\u03ba',
			],
			f: [
				'\u0399\u03b1\u03bd\u03bf\u03c5\u03ac\u03c1\u03b9\u03bf\u03c2',
				'\u03a6\u03b5\u03b2\u03c1\u03bf\u03c5\u03ac\u03c1\u03b9\u03bf\u03c2',
				'\u039c\u03ac\u03c1\u03c4\u03b9\u03bf\u03c2',
				'\u0391\u03c0\u03c1\u03af\u03bb\u03b9\u03bf\u03c2',
				'\u039c\u03ac\u03b9\u03bf\u03c2',
				'\u0399\u03bf\u03cd\u03bd\u03b9\u03bf\u03c2',
				'\u0399\u03bf\u03cd\u03bb\u03b9\u03bf\u03c2',
				'\u0391\u03cd\u03b3\u03bf\u03c5\u03c3\u03c4\u03bf\u03c2',
				'\u03a3\u03b5\u03c0\u03c4\u03ad\u03bc\u03b2\u03c1\u03b9\u03bf\u03c2',
				'\u039f\u03ba\u03c4\u03ce\u03b2\u03c1\u03b9\u03bf\u03c2',
				'\u039d\u03bf\u03ad\u03bc\u03b2\u03c1\u03b9\u03bf\u03c2',
				'\u0394\u03b5\u03ba\u03ad\u03bc\u03b2\u03c1\u03b9\u03bf\u03c2',
			],
		},
		w: {
			s: [
				'\u039a',
				'\u0394',
				'\u03a4',
				'\u03a4',
				'\u03a0',
				'\u03a0',
				'\u03a3',
			],
			f: [
				'\u039a\u03c5\u03c1\u03b9\u03b1\u03ba\u03ae',
				'\u0394\u03b5\u03c5\u03c4\u03ad\u03c1\u03b1',
				'\u03a4\u03c1\u03af\u03c4\u03b7',
				'\u03a4\u03b5\u03c4\u03ac\u03c1\u03c4\u03b7',
				'\u03a0\u03ad\u03bc\u03c0\u03c4\u03b7',
				'\u03a0\u03b1\u03c1\u03b1\u03c3\u03ba\u03b5\u03c5\u03ae',
				'\u03a3\u03ac\u03b2\u03b2\u03b1\u03c4\u03bf',
			],
		},
	}
})((globalThis.CodeCrumbs = globalThis.CodeCrumbs || {}))
